import { Component, OnInit } from '@angular/core';
import { DashboardService } from './dashboard.service';
import * as Highcharts from 'highcharts';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  cards = [];
  pie = [];
  data: Data;
  kgs;
  litres;
  regions;

  Highcharts = Highcharts;
  chartOptions: {};
  chartOptions2: {};
  chartConstructor = 'chart';
  constructor(
    private dashboardService: DashboardService,
    private router: Router
    ) {

  }

  ngOnInit() {
    this.dashboardService.getDashStats().subscribe(
      (data: Data) => {
        this.data = data;
        this.litres = data.litres;
        this.kgs = data.kgs;
        this.regions = data.regions;

        console.log(data)
      }
    );
    // this.cards = this.dashboardService.cards();
    // this.pie = this.dashboardService.pie();
    // this.dashboardService.getDashStats().subscribe(
    //   (data: Data) => {
    //     console.log(data);
    //     this.data = data;
    //     this.bigChart = data.stock;
    //   }
    // );

    this.getData();
  }

  toBusiness(category) {
    this.router.navigate(['/app/businesses', {category}]);
  }
  toValidity(status) {
    this.router.navigate(['/app/businesses', {status}]);
  }
  toPesticides() {
    this.router.navigate(['/app/pesticides']);
  }
  getData() {
    this.dashboardService.getBusinessRegions().subscribe(
      (data: any) => {

        this.businessPerRegion(data);
      }
    );
    this.dashboardService.getPesticidesType().subscribe(
      (data: any) => {

        this.pestcidesPerType(data);
      }
    );
  }

  businessPerRegion(data) {
    this.chartOptions = {
      chart: {
        type: 'column'
      },
      title: {
        text: 'Business distributions per regions',
        align: 'left'
      },
      subtitle: {
        text: '',
        align: 'left'
      },
      xAxis: {
        categories: data.categories,
        title: {
          text: null
        },
        gridLineWidth: 0,
        lineWidth: 0
      },
      yAxis: {
        // min: 0,
        title: {
          text: 'Total Businesses',
          align: 'high'
        },
        labels: {
          overflow: 'justify'
        },
        gridLineWidth: 1
      },
      tooltip: {
        valueSuffix: ' '
      },
      plotOptions: {
        bar: {
          borderRadius: '2%',
          dataLabels: {
            enabled: true
          },
          groupPadding: 0.1
        }
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
        shadow: true
      },
      credits: {
        enabled: false
      },
      series: data.series

    };
  }


  pestcidesPerType(data) {
    this.chartOptions2 = {
      chart: {
        type: 'column'
      },
      title: {
        text: 'Pesticides per type',
        align: 'left'
      },
      subtitle: {
        text: '',
        align: 'left'
      },
      xAxis: {
        categories: data.categories,
        title: {
          text: null
        },
        gridLineWidth: 0,
        lineWidth: 0
      },
      yAxis: {
        // min: 0,
        title: {
          text: 'Total',
          align: 'high'
        },
        labels: {
          overflow: 'justify'
        },
        gridLineWidth: 1
      },
      tooltip: {
        valueSuffix: ' '
      },
      plotOptions: {
        bar: {
          borderRadius: '2%',
          dataLabels: {
            enabled: true
          },
          groupPadding: 0.1
        }
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
        shadow: true
      },
      credits: {
        enabled: false
      },
      series: data.series

    };
  }

}

export interface Data {
  importers;
  sellers;
  repackers;
  fumigators;
  formulators;
  stock;
  valid;
  expired;
  pesticides;
  wholesale_sellers;
  litres;
  kgs;
  regions;
}
